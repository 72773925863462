import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text } from '../components';
import { useEffect } from 'react';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const PurchaseView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const purchaseParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( purchaseParams, [])
	const purchaseOnLoaded = (options) => {
		run([{"id":"api1683488418272","function":"api","api":{"method":"stripe.customers.subscription","email":"@firebase.user.email"},"into":"@property.subs","onsuccess":[{"id":"set1683488418272","function":"set","what":"@property.plan","value":"@property.subs.plan"},{"id":"set1683488418272","function":"set","what":"@property.premium","value":true,"if":{"what":"@property.plan.active","is":true,"and":{"what":"@property.plan.product","is":"@database.products.premium.stripe_id"}}}]}], options)
	}
	useEffect(purchaseOnLoaded, [])
	const homeStartButton1683488476620OnClick = (options) => {
		run([{"id":"goto1683488476620","function":"goto","view":"dashboard"}], options)
	}
	const text1683488463723OnClick = (options) => {
		run([{"id":"goto1683488463723","function":"goto","view":"auth_logout"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('purchase.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="homeBody1683488293138"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="homeMainArea1683488293138"
					direction="vertical"
					className="w-100 min-h-100vh h-auto position-relative" >
					<Group
						id="homeSpacerTop1683488293137"
						direction="vertical"
						className="w-100 h-fill position-relative" >
						<Group
							id="homeHeader1683488293135"
							direction="horizontal"
							className="w-100 h-auto position-relative p-32px" >
							<Image
								id="headerLogo1683488293133"
								src={logo}
								className="w-192px h-auto position-relative" />
							<Group
								id="group1683488293134"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Text
								id="text1683488463723"
								onClick={text1683488463723OnClick}
								className="w-auto h-auto valign-center position-relative ff-buttons" >
								{t('purchase.text1683488463723')}
							</Text>
						</Group>
					</Group>
					<Group
						id="startArea1683488293138"
						direction="vertical"
						className="max-w-1000px w-100 h-auto align-center position-relative p-32px" >
						<Text
							id="homeSlogan1683488293137"
							tag="h1"
							className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
							{t('purchase.homeSlogan1683488293137')}
						</Text>
						<Text
							id="homeSloganSubtitle1683488293137"
							className="w-100 h-auto align-center position-relative mt-24px text-primary-text ff-titles text-center" >
							{t('purchase.homeSloganSubtitle1683488293137')}
						</Text>
						<Text
							id="homeStartButton1683488476620"
							onClick={homeStartButton1683488476620OnClick}
							className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mt-48px" >
							{t('purchase.homeStartButton1683488476620')}
						</Text>
					</Group>
					<Group
						id="homeSpacerBottom1683488293138"
						direction="vertical"
						className="w-100 h-fill position-relative" >
					</Group>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
