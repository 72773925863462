import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input } from '../components';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const Build_langView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const continueButtonType1682813055674OnClick = (options) => {
		run([{"id":"goto1682814492561","function":"goto","view":"build_parts_agreement","if":{"what":"@database.contract.type","is":"agreement","or":{"what":"@database.contract.type","is":"loi"}}},{"id":"goto1683104563946","function":"goto","view":"build_parts_unilateral","if":{"what":"@database.contract.type","is":"unilateral"}},{"id":"goto1683104580474","function":"goto","view":"build_parts_pleading","if":{"what":"@database.contract.type","is":"pleading","or":{"what":"@database.contract.type","is":"appeal"}}}], options)
	}
	const text1683104746914OnClick = (options) => {
		run([{"id":"goto1683104746914","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683148985831","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}
	const headerLogo1683104746914OnClick = (options) => {
		run([{"id":"goto1683149018527","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683149018527","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('build_lang.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="bodyBuildType1682813055674"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="bodySpacerTop1682813055674"
					direction="vertical"
					className="w-100 h-fill position-relative" >
					<Group
						id="homeHeader1683104746914"
						direction="horizontal"
						className="w-100 h-auto position-relative p-32px" >
						<Image
							id="headerLogo1683104746914"
							onClick={headerLogo1683104746914OnClick}
							src={logo}
							className="w-192px h-auto position-relative" />
						<Group
							id="group1683104746914"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Text
							id="text1683104746914"
							onClick={text1683104746914OnClick}
							className="w-auto h-auto valign-center position-relative ff-buttons" >
							{t('build_lang.text1683104746914')}
						</Text>
					</Group>
				</Group>
				<Group
					id="formWrapperLang"
					direction="vertical"
					className="max-w-1000px w-100 h-auto align-center position-relative" >
					<Text
						id="stepTitleLang"
						tag="h1"
						className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
						{t('build_lang.stepTitleLang')}
					</Text>
					<Text
						id="stepStep1682813055674"
						className="w-100 h-auto align-center position-relative mt-24px text-accent ff-titles text-center" >
						{t('build_lang.stepStep1682813055674')}
					</Text>
					<Text
						id="stepInstructionsType1682813055674"
						className="w-100 h-auto align-center position-relative mt-6px text-primary-text ff-titles text-center" >
						{t('build_lang.stepInstructionsType1682813055674')}
					</Text>
					<Group
						id="inputWrapper1682813055674"
						direction="vertical"
						className="w-80 h-auto border-top border-bottom border-start border-end align-center position-relative p-16px mt-32px" >
						<Input.Text
							id="inputType1682813055674"
							name="text"
							src="@database.contract.language"
						size="md"
							className="w-100 min-h-100 fg-100 border-white position-relative" />
					</Group>
					<Text
						id="continueButtonType1682813055674"
						onClick={continueButtonType1682813055674OnClick}
						className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mt-48px" >
						{t('build_lang.continueButtonType1682813055674')}
					</Text>
					<Text
						id="stepSampleType1682813055674"
						className="w-100 h-auto align-center position-relative mt-48px text-secondary-text ff-titles text-center" >
						{t('build_lang.stepSampleType1682813055674')}
					</Text>
				</Group>
				<Group
					id="bodySpacerBottom1682813055674"
					direction="vertical"
					className="w-100 h-fill position-relative" >
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
