import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input } from '../components';
import { useEffect } from 'react';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const RegisterView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const registerParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( registerParams, [])
	const registerOnLoaded = (options) => {
		run([{"id":"api1682955213898","function":"api","api":{"method":"doyo.random.pin","digits":6},"into":"@cookie.code"}], options)
	}
	useEffect(registerOnLoaded, [])
	const continueButtonType1682954505529OnClick = (options) => {
		run([{"id":"set1682955055773","function":"set","what":"@property.valid","value":true},{"id":"do1682955065472","function":"do","what":[{"id":"api1682955069871","function":"api","api":{"method":"sendgrid.sendTemplate","template_id":"d-0ceb546367fa4142bfc0ac68fbd5cf74","to":"@cookie.email","dynamic_template_data":{"code":"@cookie.code"}},"onsuccess":[{"id":"goto1682955305644","function":"goto","view":"email_verification"}],"onerror":[{"id":"set1682968846520","function":"set","what":"@property.error","value":"verification_not_sent"},{"id":"popup1682968865578","function":"popup","dialog":"generic_error"}]}],"if":{"what":"@property.valid","is":true}}], options)
	}
	const text1683149211598OnClick = (options) => {
		run([{"id":"set1683149658035","function":"set","what":"@property.isCreatingDocument","value":true},{"id":"goto1683149266081","function":"goto","view":"auth"}], options)
	}
	const headerLogo1682954505529OnClick = (options) => {
		run([{"id":"goto1682954505529","function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('register.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="bodyBuildType1682954505529"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="bodySpacerTop1682954505529"
					direction="vertical"
					className="w-100 h-fill position-relative" >
					<Group
						id="homeHeader1682954505529"
						direction="horizontal"
						className="w-100 h-auto position-relative p-32px" >
						<Image
							id="headerLogo1682954505529"
							onClick={headerLogo1682954505529OnClick}
							src={logo}
							className="w-192px h-auto position-relative" />
						<Group
							id="group1683149240596"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Text
							id="text1683149211598"
							onClick={text1683149211598OnClick}
							className="w-auto h-auto valign-center position-relative ff-buttons" >
							{t('register.text1683149211598')}
						</Text>
					</Group>
				</Group>
				<Group
					id="formWrapperLang1682954505529"
					direction="vertical"
					className="max-w-1000px w-100 h-auto align-center position-relative" >
					<Text
						id="stepTitleLang1682954505529"
						tag="h1"
						className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
						{t('register.stepTitleLang1682954505529')}
					</Text>
					<Text
						id="stepStep1682954505529"
						className="w-100 h-auto align-center position-relative mt-24px text-accent ff-titles text-center" >
						{t('register.stepStep1682954505529')}
					</Text>
					<Text
						id="stepInstructionsType1682954505529"
						className="w-100 h-auto align-center position-relative mt-6px text-primary-text ff-titles text-center" >
						{t('register.stepInstructionsType1682954505529')}
					</Text>
					<Group
						id="inputWrapper1682954505529"
						direction="vertical"
						className="w-80 h-auto border-top border-bottom border-start border-end align-center position-relative p-16px mt-32px" >
						<Input.Text
							id="inputType1682954505529"
							name="text"
							src="@cookie.email"
						size="md"
							className="w-100 min-h-100 fg-100 border-white position-relative" />
					</Group>
					<Text
						id="continueButtonType1682954505529"
						onClick={continueButtonType1682954505529OnClick}
						className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mt-48px" >
						{t('register.continueButtonType1682954505529')}
					</Text>
				</Group>
				<Group
					id="bodySpacerBottom1682954505529"
					direction="vertical"
					className="w-100 h-fill position-relative" >
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
