import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input } from '../components';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const Build_infoView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const continueButtonType1682814503300OnClick = (options) => {
		run([{"id":"goto1682814503300","function":"goto","view":"build_country"}], options)
	}
	const text1683149077927OnClick = (options) => {
		run([{"id":"goto1683149077927","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683149077927","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}
	const headerLogo1683104735374OnClick = (options) => {
		run([{"id":"goto1683149088475","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683149088475","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('build_info.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="bodyBuildType1682814503300"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="bodySpacerTop1682814503300"
					direction="vertical"
					className="w-100 h-fill position-relative" >
					<Group
						id="homeHeader1683104735374"
						direction="horizontal"
						className="w-100 h-auto position-relative p-32px" >
						<Image
							id="headerLogo1683104735374"
							onClick={headerLogo1683104735374OnClick}
							src={logo}
							className="w-192px h-auto position-relative" />
						<Group
							id="group1683104735374"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Text
							id="text1683149077927"
							onClick={text1683149077927OnClick}
							className="w-auto h-auto valign-center position-relative ff-buttons" >
							{t('build_info.text1683149077927')}
						</Text>
					</Group>
				</Group>
				<Group
					id="formWrapperLang1682814503300"
					direction="vertical"
					className="max-w-1000px w-100 h-auto align-center position-relative" >
					<Text
						id="stepTitleLang1682814503300"
						tag="h1"
						className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
						{t('build_info.stepTitleLang1682814503300')}
					</Text>
					<Text
						id="stepStep1682814503300"
						className="w-100 h-auto align-center position-relative mt-24px text-accent ff-titles text-center" >
						{t('build_info.stepStep1682814503300')}
					</Text>
					<Text
						id="stepInstructionsType1682814503300"
						className="w-100 h-auto align-center position-relative mt-6px text-primary-text ff-titles text-center" >
						{t('build_info.stepInstructionsType1682814503300')}
					</Text>
					<Group
						id="inputWrapper1682814503300"
						direction="vertical"
						className="w-80 h-auto border-top border-bottom border-start border-end align-center position-relative p-16px mt-32px" >
						<Input.TextArea
							id="inputType1682814503300"
							name="text"
							src="@database.contract.intentions"
						size="md"
							className="w-100 min-h-400px h-auto border-white position-relative" />
					</Group>
					<Text
						id="continueButtonType1682814503300"
						onClick={continueButtonType1682814503300OnClick}
						className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mt-48px" >
						{t('build_info.continueButtonType1682814503300')}
					</Text>
					<Text
						id="stepSampleType1682814503300"
						className="w-100 h-auto align-center position-relative mt-48px text-secondary-text ff-titles text-center" >
						{t('build_info.stepSampleType1682814503300')}
					</Text>
				</Group>
				<Group
					id="bodySpacerBottom1682814503300"
					direction="vertical"
					className="w-100 h-fill position-relative" >
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
