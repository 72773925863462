import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Icon, Image, Text, List } from '../components';
import { useEffect } from 'react';
import type_assesment from '../assets/images/type_assesment.jpg';
import ic_documents from '../assets/iconset/ic_documents.svg';
import ic_create from '../assets/iconset/ic_create.svg';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const DashboardView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const dashboardParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( dashboardParams, [])
	const dashboardOnLoaded = (options) => {
		run([{"id":"set1683058928985","function":"set","what":"@cookie.fullMenu","value":true},{"id":"api1683389816132","function":"api","api":{"method":"stripe.customers.subscription","email":"@firebase.user.email"},"into":"@property.subs","if":{"what":"@property.loaded","is_not":true},"onsuccess":[{"id":"set1683390256267","function":"set","what":"@property.plan","value":"@property.subs.plan"},{"id":"set1683390382424","function":"set","what":"@property.premium","value":true,"if":{"what":"@property.plan.active","is":true,"and":{"what":"@property.plan.product","is":"@database.products.premium.stripe_id"}}}]},{"id":"set1683488680880","function":"set","what":"@property.loaded","value":true}], options)
	}
	useEffect(dashboardOnLoaded, [])
	const doyoUrlDashboardOnClick = (options) => {
		run([{"id":"browser1683058259411","function":"browser","url":"https:\/\/doyo.tech","target":"_doyo"}], options)
	}
	const typeCell1683132082815OnClick = (options) => {
		run([{"id":"set1683132082815","function":"set","what":"@property.document","value":"@element.documentList.value"},{"id":"set1683154118439","function":"set","what":"@property.documentID","value":"@element.documentList.selected"},{"id":"goto1683132082815","function":"goto","view":"document"}], options)
	}
	const continueButtonType1683148009149OnClick = (options) => {
		run([{"id":"goto1683154334639","function":"goto","view":"build_type"}], options)
	}
	const newDocButtonOnClick = (options) => {
		run([{"id":"goto1683148156690","function":"goto","view":"build_type"}], options)
	}
	const text1683058259411OnClick = (options) => {
		run([{"id":"goto1683058259411","function":"goto","view":"auth_logout"}], options)
	}
	const goPremiumButtonOnClick = (options) => {
		run([{"id":"goto1683414394181","function":"goto","view":"premium"}], options)
	}
	const menuTogglerOnClick = (options) => {
		run([{"id":"set1683059307557","function":"set","what":"@cookie.fullMenu","value":true,"if":{"what":"@cookie.fullMenu","is":false,"else":[{"id":"set1683059344929","function":"set","what":"@cookie.fullMenu","value":false}]}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('dashboard.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="dashboardBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="dashboardHeader"
					direction="horizontal"
					className="w-100 h-auto position-relative p-32px" >
					<Icon
						id="menuToggler"
						if={{"what":"@property.showMenu","is":true}}
						onClick={menuTogglerOnClick}
						src="menu"
						size={3}
						className="w-auto h-auto position-relative"
					/>
					<Image
						id="headerLogo1683058259411"
						src={logo}
						className="w-192px h-auto valign-center position-relative ms-24px" />
					<Group
						id="group1683058259411"
						direction="vertical"
						className="w-fill h-auto position-relative" >
					</Group>
					<Text
						id="goPremiumButton"
						if={{"what":"@property.premium","is_not":true}}
						onClick={goPremiumButtonOnClick}
						className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px me-24px text-accent" >
						{t('dashboard.goPremiumButton')}
					</Text>
					<Text
						id="text1683058259411"
						onClick={text1683058259411OnClick}
						className="w-auto h-auto valign-center position-relative ff-buttons" >
						{t('dashboard.text1683058259411')}
					</Text>
				</Group>
				<Group
					id="dashboardSubheader"
					direction="horizontal"
					className="w-100 h-auto position-relative bg-light px-32px py-24px" >
					<Text
						id="stepTitleLang1683147932109"
						tag="h3"
						className="w-auto h-auto align-center valign-center position-relative text-primary ff-titles text-start" >
						{t('dashboard.stepTitleLang1683147932109')}
					</Text>
					<Group
						id="group1683147991522"
						direction="vertical"
						className="w-fill h-auto position-relative" >
					</Group>
					<Group
						id="newDocButton"
						onClick={newDocButtonOnClick}
						direction="vertical"
						className="w-auto h-auto position-relative bg-text-on-primary p-1px" >
						<Text
							id="continueButtonType1683148009149"
							onClick={continueButtonType1683148009149OnClick}
							className="w-auto h-auto align-center position-relative bg-primary-light px-48px py-16px text-text-on-primary" >
							{t('dashboard.continueButtonType1683148009149')}
						</Text>
					</Group>
				</Group>
				<Group
					id="dashboardArea"
					direction="horizontal"
					className="w-100 min-h-100vh h-fill position-relative bg-lightest" >
					<Group
						id="sidemenuWrapper"
						if={{"what":"@property.showMenu","is":true}}
						direction="horizontal"
						className="w-auto min-h-100 h-100 position-relative bg-primary-light p-16px" >
						<Group
							id="sideMenuIcons"
							direction="vertical"
							className="w-auto h-auto position-relative p-8px" >
							<Group
								id="group1683059130425"
								direction="vertical"
								className="w-32px min-h-32px h-32px position-relative p-6px" >
								<Image
									id="image1683059150275"
									src={ic_create}
									className="w-100 h-auto position-relative" />
							</Group>
							<Group
								id="group1683062265290"
								direction="vertical"
								className="w-32px min-h-32px h-32px position-relative p-6px mt-24px" >
								<Image
									id="image1683062265290"
									src={ic_documents}
									className="w-100 h-auto position-relative" />
							</Group>
						</Group>
						<Group
							id="sideMenuTexts"
							if={{"what":"@cookie.fullMenu","is":true}}
							direction="vertical"
							className="w-auto h-auto position-relative p-8px" >
							<Group
								id="group1683059180660"
								direction="vertical"
								className="w-auto min-h-32px h-32px valign-center position-relative" >
								<Text
									id="text1683059188220"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('dashboard.text1683059188220')}
								</Text>
							</Group>
							<Group
								id="group1683062287619"
								direction="vertical"
								className="w-auto min-h-32px h-32px valign-center position-relative mt-24px" >
								<Text
									id="text1683062287619"
									className="w-auto h-auto position-relative text-text-on-primary ff-regular" >
									{t('dashboard.text1683062287619')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="group1683058867280"
						direction="vertical"
						className="w-fill h-auto position-relative p-48px" >
						<Group
							id="formWrapperLang1683062623351"
							direction="vertical"
							className="w-100 h-auto align-center position-relative" >
							<Group
								id="partsType1683062623351"
								direction="horizontal"
								className="w-100 h-auto position-relative" >
								<List
									id="documentList"
									direction="auto"
									src="@firebase.firestore.documents"
									filter={{"what":"user_id","is":"@firebase.user.id"}}
									columns={"auto"}
									className="w-100 h-auto position-relative" >
									<Group
										id="typeCell1683132082815"
										onClick={typeCell1683132082815OnClick}
										direction="vertical"
										className="min-w-300px w-fill h-auto border-top border-bottom border-start border-end position-relative m-8px" >
										<Image
											id="typeImage1683132082815"
											fallback={type_assesment}
											src="@database.types.(@field.type).image"
											className="w-100 h-auto position-relative" />
										<Text
											id="typeTitle1683132082815"
											src="description"
											className="w-100 h-auto position-relative p-16px text-primary ff-regular" >
											{t('dashboard.typeTitle1683132082815')}
										</Text>
										<Text
											id="typeDesc1683132082815"
											tag="small"
											src="name"
											className="w-100 h-auto position-relative px-16px pb-16px text-secondary-text ff-regular" >
											{t('dashboard.typeDesc1683132082815')}
										</Text>
										<Text
											id="typeDesc1683148212431"
											tag="small"
											src="@database.types.(@field.type).title.(@cookie.lang)"
											className="w-100 h-auto position-relative px-16px pb-16px text-accent ff-regular" >
											{t('dashboard.typeDesc1683148212431')}
										</Text>
									</Group>
								</List>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="dashboardFooter"
					direction="auto"
					className="w-100 h-auto position-relative bg-primary p-24px" >
					<Group
						id="homeCopyrightArea1683058259411"
						direction="vertical"
						className="w-fill h-auto position-relative" >
						<Text
							id="copyrightLabelDashboard"
							className="w-100 h-auto position-relative text-text-on-primary ff-regular" >
							{t('dashboard.copyrightLabelDashboard')}
						</Text>
					</Group>
					<Group
						id="homeDoyoInfo1683058259411"
						direction="vertical"
						className="min-w-300px w-fill h-auto position-relative" >
						<Text
							id="builtWithDoyoDashboard"
							className="w-100 h-auto position-relative text-text-on-primary ff-bold" >
							{t('dashboard.builtWithDoyoDashboard')}
						</Text>
						<Text
							id="aboutDoyoDashboard"
							className="w-100 h-auto position-relative mt-16px text-secondary-text ff-regular" >
							{t('dashboard.aboutDoyoDashboard')}
						</Text>
						<Text
							id="doyoUrlDashboard"
							onClick={doyoUrlDashboardOnClick}
							className="w-auto h-auto position-relative mt-16px text-secondary-text ff-regular" >
							{t('dashboard.doyoUrlDashboard')}
						</Text>
					</Group>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
