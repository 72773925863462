import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Auth_destinationView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const auth_destinationParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( auth_destinationParams, [])
	const auth_destinationOnLoaded = (options) => {
		run([{"id":"do1683149765547","function":"do","if":{"what":"@property.isCreatingDocument","is":true},"what":[{"id":"add1683149765547","function":"add","what":{"user_id":"@firebase.user.id","timestamp":"@api.doyo.timestamp","type":"@database.contract.type","part_1":"@database.contract.part_1","part_2":"@database.contract.part_2","part_3":"@database.contract.part_3","language":"@database.contract.language","country":"@database.contract.country","description":"@database.contract.description","intentions":"@database.contract.intentions","antecedents":"@database.contract.antecedents"},"to":"@firebase.firestore.generations","key":"@firebase.user.id"},{"id":"set1683149765547","function":"set","what":"@cookie.generationID","value":"@firebase.user.id"},{"id":"goto1683149765547","function":"goto","view":"generating"}]},{"id":"goto1683058343918","function":"goto","view":"dashboard","if":{"what":"@property.isCreatingDocument","is_not":true}},{"id":"set1683548705680","function":"set","what":"@property.isCreatingDocument","value":false}], options)
	}
	useEffect(auth_destinationOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('auth_destination.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="logoutBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
