import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Icon, Text } from '../components';
import { useEffect } from 'react';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const PremiumView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const premiumParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( premiumParams, [])
	const premiumOnLoaded = (options) => {
		run([{"id":"set1683390511013","function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","is":"es","else":[{"id":"set1683390511013","function":"set","what":"@cookie.lang","value":"en"}]}}], options)
	}
	useEffect(premiumOnLoaded, [])
	const homeStartButton1683390511013OnClick = (options) => {
		run([{"id":"goto1683390511013","function":"goto","view":"checkout"}], options)
	}
	const premiumCancelButtonOnClick = (options) => {
		run([{"id":"goto1683495433443","function":"goto","view":"dashboard"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('premium.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="homeBody1683390511013"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="homeMainArea1683390511013"
					direction="vertical"
					className="w-100 min-h-100vh h-auto position-relative" >
					<Group
						id="homeSpacerTop1683390511013"
						direction="vertical"
						className="w-100 h-fill position-relative" >
						<Group
							id="homeHeader1683390511013"
							direction="horizontal"
							className="w-100 h-auto position-relative p-32px" >
							<Image
								id="headerLogo1683390511013"
								src={logo}
								className="w-192px h-auto position-relative" />
							<Group
								id="group1683390511013"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Icon
								id="premiumCancelButton"
								onClick={premiumCancelButtonOnClick}
								src="close"
								size={3}
								className="w-auto h-auto position-relative"
							/>
						</Group>
					</Group>
					<Group
						id="startArea1683390511013"
						direction="vertical"
						className="max-w-1000px w-100 h-auto align-center position-relative p-32px" >
						<Text
							id="homeSlogan1683390511013"
							tag="h1"
							className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
							{t('premium.homeSlogan1683390511013')}
						</Text>
						<Text
							id="homeSloganSubtitle1683390511013"
							className="w-100 h-auto align-center position-relative mt-24px text-primary-text ff-titles text-center" >
							{t('premium.homeSloganSubtitle1683390511013')}
						</Text>
						<Text
							id="homeSloganSubtitle1683397331854"
							tag="h3"
							className="w-100 h-auto align-center position-relative mt-24px text-accent ff-regular text-center" >
							{t('premium.homeSloganSubtitle1683397331854')}
						</Text>
						<Text
							id="homeSloganSubtitle1683397436220"
							tag="h3"
							className="w-100 h-auto align-center position-relative mt-20px text-accent ff-regular text-center" >
							{t('premium.homeSloganSubtitle1683397436220')}
						</Text>
						<Text
							id="homeSloganSubtitle1683397489175"
							tag="h3"
							className="w-100 h-auto align-center position-relative mt-20px text-accent ff-regular text-center" >
							{t('premium.homeSloganSubtitle1683397489175')}
						</Text>
						<Text
							id="homeSloganSubtitle1683397577305"
							tag="h3"
							className="w-100 h-auto align-center position-relative mt-20px text-accent ff-regular text-center" >
							{t('premium.homeSloganSubtitle1683397577305')}
						</Text>
						<Text
							id="homeSloganSubtitle1683397622965"
							tag="h3"
							className="w-100 h-auto align-center position-relative mt-20px text-primary ff-titles text-center" >
							{t('premium.homeSloganSubtitle1683397622965')}
						</Text>
						<Text
							id="homeStartButton1683390511013"
							onClick={homeStartButton1683390511013OnClick}
							className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mt-48px" >
							{t('premium.homeStartButton1683390511013')}
						</Text>
					</Group>
					<Group
						id="homeSpacerBottom1683390511013"
						direction="vertical"
						className="w-100 h-fill position-relative" >
					</Group>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
