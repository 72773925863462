import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, List } from '../components';
import { useEffect } from 'react';
import type_assesment from '../assets/images/type_assesment.jpg';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const Build_typeView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const build_typeParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( build_typeParams, [])
	const build_typeOnLoaded = (options) => {
		run([{"id":"set1683111339904","function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","is":"es","else":[{"id":"set1683111396316","function":"set","what":"@cookie.lang","value":"en"}]}}], options)
	}
	useEffect(build_typeOnLoaded, [])
	const typeCell1683104347124OnClick = (options) => {
		run([{"id":"set1683104347124","function":"set","what":"@database.contract.type","value":"@element.typesList.selected"},{"id":"goto1683104347124","function":"goto","view":"build_description"}], options)
	}
	const text1683149025668OnClick = (options) => {
		run([{"id":"goto1683149025668","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683149025668","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}
	const headerLogo1683104759435OnClick = (options) => {
		run([{"id":"goto1683149039012","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683149039012","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('build_type.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="bodyBuildType1682813305751"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="bodySpacerTop1682813305751"
					direction="vertical"
					className="w-100 h-fill position-relative" >
					<Group
						id="homeHeader1683104759435"
						direction="horizontal"
						className="w-100 h-auto position-relative p-32px" >
						<Image
							id="headerLogo1683104759435"
							onClick={headerLogo1683104759435OnClick}
							src={logo}
							className="w-192px h-auto position-relative" />
						<Group
							id="group1683104759435"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Text
							id="text1683149025668"
							onClick={text1683149025668OnClick}
							className="w-auto h-auto valign-center position-relative ff-buttons" >
							{t('build_type.text1683149025668')}
						</Text>
					</Group>
				</Group>
				<Group
					id="formWrapperLang1682813305751"
					direction="vertical"
					className="max-w-1000px w-100 h-auto align-center position-relative" >
					<Text
						id="stepTitleLang1682813305751"
						tag="h1"
						className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
						{t('build_type.stepTitleLang1682813305751')}
					</Text>
					<Text
						id="stepStep1682813305751"
						className="w-100 h-auto align-center position-relative mt-24px text-accent ff-titles text-center" >
						{t('build_type.stepStep1682813305751')}
					</Text>
					<Text
						id="stepInstructionsType1682813305751"
						className="w-100 h-auto align-center position-relative mt-6px text-primary-text ff-titles text-center" >
						{t('build_type.stepInstructionsType1682813305751')}
					</Text>
					<Group
						id="partsType"
						direction="horizontal"
						className="w-100 h-auto position-relative mt-32px" >
						<List
							id="typesList"
							direction="auto"
							src="@database.types"
							columns={"auto"}
							className="w-100 h-auto position-relative" >
							<Group
								id="typeCell1683104347124"
								onClick={typeCell1683104347124OnClick}
								direction="vertical"
								className="min-w-300px w-fill h-auto border-top border-bottom border-start border-end position-relative m-8px" >
								<Image
									id="typeImage"
									fallback={type_assesment}
									src="image"
									className="w-100 h-auto position-relative" />
								<Text
									id="typeTitle"
									src="@database.types.(@field.id).title.(@cookie.lang)"
									className="w-100 h-auto position-relative p-16px text-primary ff-regular" >
									{t('build_type.typeTitle')}
								</Text>
								<Text
									id="typeDesc"
									tag="small"
									src="@database.types.(@field.id).description.(@cookie.lang)"
									className="w-100 h-auto position-relative px-16px pb-16px text-secondary-text ff-regular" >
									{t('build_type.typeDesc')}
								</Text>
							</Group>
						</List>
					</Group>
				</Group>
				<Group
					id="bodySpacerBottom1682813305751"
					direction="vertical"
					className="w-100 h-fill position-relative" >
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
