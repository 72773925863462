import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Must_be_premiumDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const must_be_premium_buttons_positiveOnClick = (options) => {
		run([{"id":"goto1683489119292","function":"goto","view":"premium"},{"function":"hide","dialog":"must_be_premium"}], options)
	}
	const must_be_premium_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"must_be_premium"}], options)
	}

  return (
    
    
			<Modal id="must_be_premium" centered>
					<Modal.Header>
						<Modal.Title>{t('must_be_premium.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="verification_error1683488801471"
							className="w-100 h-auto position-relative" >
							{t('must_be_premium.verification_error1683488801471')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="must_be_premium_buttons_negative"
							onClick={must_be_premium_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('must_be_premium.must_be_premium_buttons_negative')}
						</Button>
						<Button
							id="must_be_premium_buttons_positive"
							onClick={must_be_premium_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('must_be_premium.must_be_premium_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Must_be_premiumDialog;
