import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextProviders } from './context';


// Custom imports:
import Dialogs from './dialogs';
import { AuthView, Auth_destinationView, Auth_logoutView, Build_antecedentsView, Build_countryView, Build_descriptionView, Build_infoView, Build_langView, Build_parts_agreementView, Build_parts_pleadingView, Build_parts_unilateralView, Build_typeView, CheckoutView, DashboardView, DocumentView, Email_verificationView, GeneratingView, HomeView, PdfgenerationView, PremiumView, PreviewView, PurchaseView, RegisterView } from './pages';
import PrivateRoute from './PrivateRoute';


import './styles/main.scss';

function App() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
          <title>{t('title')}</title>
          <meta name="description" content={t('description')} data-react-helmet="true" />
      </Helmet>
      <Router>
        <ContextProviders>
          <Switch>
            <Route exact path="/" component={HomeView} />
						<Route exact path="/auth" component={AuthView} />
<Route exact path="/auth/:params(.+)" component={AuthView} />
						<PrivateRoute exact path="/auth_destination" component={Auth_destinationView} />
						<PrivateRoute exact path="/auth_destination/:params(.+)" component={Auth_destinationView} />
						<Route exact path="/auth_logout" component={Auth_logoutView} />
						<Route exact path="/auth_logout/:params(.+)" component={Auth_logoutView} />
						<Route exact path="/build_antecedents" component={Build_antecedentsView} />
						<Route exact path="/build_antecedents/:params(.+)" component={Build_antecedentsView} />
						<Route exact path="/build_country" component={Build_countryView} />
						<Route exact path="/build_country/:params(.+)" component={Build_countryView} />
						<Route exact path="/build_description" component={Build_descriptionView} />
						<Route exact path="/build_description/:params(.+)" component={Build_descriptionView} />
						<Route exact path="/build_info" component={Build_infoView} />
						<Route exact path="/build_info/:params(.+)" component={Build_infoView} />
						<Route exact path="/build_lang" component={Build_langView} />
						<Route exact path="/build_lang/:params(.+)" component={Build_langView} />
						<Route exact path="/build_parts_agreement" component={Build_parts_agreementView} />
						<Route exact path="/build_parts_agreement/:params(.+)" component={Build_parts_agreementView} />
						<Route exact path="/build_parts_pleading" component={Build_parts_pleadingView} />
						<Route exact path="/build_parts_pleading/:params(.+)" component={Build_parts_pleadingView} />
						<Route exact path="/build_parts_unilateral" component={Build_parts_unilateralView} />
						<Route exact path="/build_parts_unilateral/:params(.+)" component={Build_parts_unilateralView} />
						<Route exact path="/build_type" component={Build_typeView} />
						<Route exact path="/build_type/:params(.+)" component={Build_typeView} />
						<Route exact path="/checkout" component={CheckoutView} />
						<Route exact path="/checkout/:params(.+)" component={CheckoutView} />
						<PrivateRoute exact path="/dashboard" component={DashboardView} />
						<PrivateRoute exact path="/dashboard/:params(.+)" component={DashboardView} />
						<PrivateRoute exact path="/document" component={DocumentView} />
						<PrivateRoute exact path="/document/:params(.+)" component={DocumentView} />
						<Route exact path="/email_verification" component={Email_verificationView} />
						<Route exact path="/email_verification/:params(.+)" component={Email_verificationView} />
						<PrivateRoute exact path="/generating" component={GeneratingView} />
						<PrivateRoute exact path="/generating/:params(.+)" component={GeneratingView} />
						<Route exact path="/home" component={HomeView} />
						<Route exact path="/home/:params(.+)" component={HomeView} />
						<Route exact path="/pdfgeneration" component={PdfgenerationView} />
						<Route exact path="/pdfgeneration/:params(.+)" component={PdfgenerationView} />
						<Route exact path="/premium" component={PremiumView} />
						<Route exact path="/premium/:params(.+)" component={PremiumView} />
						<Route exact path="/preview" component={PreviewView} />
						<Route exact path="/preview/:params(.+)" component={PreviewView} />
						<Route exact path="/purchase" component={PurchaseView} />
						<Route exact path="/purchase/:params(.+)" component={PurchaseView} />
						<Route exact path="/register" component={RegisterView} />
						<Route exact path="/register/:params(.+)" component={RegisterView} />
						<PrivateRoute exact path="/auth_destination" component={Auth_destinationView} />
						<PrivateRoute exact path="/auth_destination/:params(.+)" component={Auth_destinationView} />
						<Route exact path="*" component={HomeView} />
						
          </Switch>

					<Dialogs />
        </ContextProviders>
      </Router>
    </>
  );
}

export default App;
