import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Lottie } from '../components';
import { useEffect } from 'react';
import progress from '../assets/animations/progress.json';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const PreviewView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const previewParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( previewParams, [])
	const previewOnLoaded = (options) => {
		run([{"id":"set1683046867703","function":"set","what":"@property.showCurtain","value":false},{"id":"set1683118567085","function":"set","what":"@cookie.generation","value":"@firebase.firestore.generations.(@firebase.user.id)"},{"id":"api1683488722787","function":"api","api":{"method":"stripe.customers.subscription","email":"@firebase.user.email"},"into":"@property.subs","if":{"what":"@property.loaded","is_not":true},"onsuccess":[{"id":"set1683488722787","function":"set","what":"@property.plan","value":"@property.subs.plan"},{"id":"set1683488722787","function":"set","what":"@property.premium","value":true,"if":{"what":"@property.plan.active","is":true,"and":{"what":"@property.plan.product","is":"@database.products.premium.stripe_id"}}}]},{"id":"set1683488722787","function":"set","what":"@property.loaded","value":true}], options)
	}
	useEffect(previewOnLoaded, [])
	const continueButtonType1683041044687OnClick = (options) => {
		run([{"id":"set1683105419619","function":"set","what":"@property.name","value":"@database.contract.parts"},{"id":"popup1683105364554","function":"popup","dialog":"document_name"}], options)
	}
	const continueButtonType1683041013341OnClick = (options) => {
		run([{"id":"do1683488755462","function":"do","what":[{"id":"set1683488761035","function":"set","what":"@property.showCurtain","value":true},{"id":"add1683488761035","function":"add","what":{"created_at":"@cookie.timestamp","user_id":"@firebase.user.id","doc_content":"@cookie.generation.doc_content"},"to":"@firebase.firestore.pdfs","key":"@firebase.user.id"},{"id":"set1683488761035","function":"set","what":"@property.pdfID","value":"@firebase.last.id"},{"id":"api1683488761035","function":"api","api":{"method":"doppio.pdf.sync","url":"https:\/\/aidocumenter.com\/pdfgeneration?id=(@firebase.user.id)"},"into":"@cookie.response","onsuccess":[{"id":"set1683488761035","function":"set","what":"@property.showCurtain","value":false},{"id":"set1683488761035","function":"set","what":"@cookie.url","value":"@cookie.response.documentUrl"},{"id":"download1683488761035","function":"download","url":"@cookie.url","name":"document.pdf"}],"onerror":[{"id":"set1683488761035","function":"set","what":"@property.showCurtain","value":false},{"id":"set1683488761035","function":"set","what":"@property.error","value":"pdf_error"},{"id":"popup1683488761035","function":"popup","dialog":"generic_error"}]}],"if":{"what":"@property.premium","is":true}},{"id":"do1683497148709","function":"do","what":[{"id":"popup1683497170972","function":"popup","dialog":"must_be_premium"}],"if":{"what":"@property.premium","is_not":true}}], options)
	}
	const headerLogo1682928151241OnClick = (options) => {
		run([{"id":"goto1682928151241","function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('preview.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="bodyGenerating1682847610627"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative bg-light" >
				<Group
					id="spacerTopBuilding1682847610626"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="homeHeader1682928151241"
						direction="vertical"
						className="w-100 h-auto position-relative p-32px" >
						<Image
							id="headerLogo1682928151241"
							onClick={headerLogo1682928151241OnClick}
							src={logo}
							className="w-192px h-auto position-relative" />
					</Group>
				</Group>
				<Group
					id="contentBuilding1682847610626"
					direction="vertical"
					className="w-100 h-fill position-relative p-16px scrollable" >
					<Group
						id="group1682882838046"
						direction="vertical"
						className="max-w-1000px w-100 h-auto align-center position-relative" >
						<Text
							id="textPreview1682882867012"
							format={{"type":"html"}}
							tag="h3"
							className="w-100 h-auto align-center position-relative text-primary ff-titles text-start" >
							{t('preview.textPreview1682882867012')}
						</Text>
						<Group
							id="previewContent"
							direction="vertical"
							className="w-100 h-auto border-radius-20px position-relative bg-white p-32px mt-32px" >
							<Text
								id="textPreview"
								format={{"type":"html"}}
								src="@cookie.generation.doc_content"
								className="w-100 h-auto align-center position-relative ff-regular" >
								{t('preview.textPreview')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="spacerBottomBuilding1682847610627"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="previewButtons"
						direction="horizontal"
						className="w-auto h-auto align-center position-relative p-24px" >
						<Text
							id="continueButtonType1683041013341"
							onClick={continueButtonType1683041013341OnClick}
							className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mx-8px" >
							{t('preview.continueButtonType1683041013341')}
						</Text>
						<Text
							id="continueButtonType1683041044687"
							onClick={continueButtonType1683041044687OnClick}
							className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative bg-accent px-48px py-16px mx-8px text-text-on-accent" >
							{t('preview.continueButtonType1683041044687')}
						</Text>
					</Group>
				</Group>
			</Group>
			<Group
				id="previewCurtain"
				if={{"what":"@property.showCurtain","is":true}}
				direction="horizontal"
				className="w-100 min-h-100vh h-100vh position-absolute bg-lighten" >
				<Group
					id="contentBuilding1683131423686"
					direction="vertical"
					className="w-100 h-auto valign-center position-relative" >
					<Text
						id="stepTitleLang1683131423686"
						tag="h1"
						className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
						{t('preview.stepTitleLang1683131423686')}
					</Text>
					<Lottie
						id="lottie1683131423686"
						src={progress}
						className="w-128px min-h-128px h-128px align-center position-relative m-48px" />
					<Text
						id="stepStep1683131423686"
						className="w-100 h-auto align-center position-relative mt-24px text-accent ff-titles text-center" >
						{t('preview.stepStep1683131423686')}
					</Text>
					<Text
						id="stepInstructionsType1683131423686"
						className="w-100 h-auto align-center position-relative mt-6px text-primary-text ff-titles text-center" >
						{t('preview.stepInstructionsType1683131423686')}
					</Text>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
