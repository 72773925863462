import React from 'react';

// Custom imports:
import Auth_dialogDialog from './Auth_dialogDialog';
import Auth_termsDialog from './Auth_termsDialog';
import Document_nameDialog from './Document_nameDialog';
import Generic_errorDialog from './Generic_errorDialog';
import Main_dialogDialog from './Main_dialogDialog';
import Must_be_premiumDialog from './Must_be_premiumDialog';
import Storage_limitDialog from './Storage_limitDialog';



export const Dialogs = () => {
  return (
    <>
      
			<Auth_dialogDialog />
			<Auth_termsDialog />
			<Document_nameDialog />
			<Generic_errorDialog />
			<Main_dialogDialog />
			<Must_be_premiumDialog />
			<Storage_limitDialog />
      
    </>
  );
};

export default Dialogs;
