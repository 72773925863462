// Custom imports:
import products from '../../assets/data/products.json';
import document from '../../assets/data/document.json';
import types from '../../assets/data/types.json';
import response from '../../assets/data/response.json';
import contract from '../../assets/data/contract.json';

export const IS_READONLY = {
  // Readonly databases:
	types: true,
};

export const DATABASES = {
  INITIAL_STATE: {
    database: {
      // Databases:
			products,
			document,
			types,
			response,
			contract,
    },
  },

  SET: 'DATABASES_SET',
};

export const DatabasesReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case DATABASES.SET:
      draft.database = {
        ...draft.database,
        ...payload,
      };
      break;

    default:
      return draft;
  }
};
