import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input } from '../components';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const Build_parts_pleadingView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const continueButtonType1682873855582OnClick = (options) => {
		run([{"id":"goto1682873855582","function":"goto","view":"build_antecedents"}], options)
	}
	const text1683149117229OnClick = (options) => {
		run([{"id":"goto1683149117229","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683149117229","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}
	const headerLogo1683104777338OnClick = (options) => {
		run([{"id":"goto1683149127097","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683149127097","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('build_parts_pleading.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="bodyBuildType1682873855582"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="bodySpacerTop1682873855582"
					direction="vertical"
					className="w-100 h-fill position-relative" >
					<Group
						id="homeHeader1683104777338"
						direction="horizontal"
						className="w-100 h-auto position-relative p-32px" >
						<Image
							id="headerLogo1683104777338"
							onClick={headerLogo1683104777338OnClick}
							src={logo}
							className="w-192px h-auto position-relative" />
						<Group
							id="group1683104777338"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Text
							id="text1683149117229"
							onClick={text1683149117229OnClick}
							className="w-auto h-auto valign-center position-relative ff-buttons" >
							{t('build_parts_pleading.text1683149117229')}
						</Text>
					</Group>
				</Group>
				<Group
					id="formWrapperLang1682873855582"
					direction="vertical"
					className="max-w-1000px w-100 h-auto align-center position-relative" >
					<Text
						id="stepTitleLang1682873855582"
						tag="h1"
						className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
						{t('build_parts_pleading.stepTitleLang1682873855582')}
					</Text>
					<Text
						id="stepStep1682873855582"
						className="w-100 h-auto align-center position-relative mt-24px text-accent ff-titles text-center" >
						{t('build_parts_pleading.stepStep1682873855582')}
					</Text>
					<Text
						id="stepInstructionsType1682873855582"
						className="w-100 h-auto align-center position-relative mt-6px text-primary-text ff-titles text-center" >
						{t('build_parts_pleading.stepInstructionsType1682873855582')}
					</Text>
					<Group
						id="partsWrapper1682873855582"
						direction="vertical"
						className="w-80 h-auto align-center position-relative mt-48px" >
						<Group
							id="partSignerPleading"
							direction="vertical"
							className="w-100 h-auto position-relative" >
							<Text
								id="pleadingLabelA"
								className="w-100 h-auto position-relative ff-regular" >
								{t('build_parts_pleading.pleadingLabelA')}
							</Text>
							<Group
								id="pleadingRowA"
								direction="vertical"
								className="w-100 h-auto border-top border-bottom border-start border-end align-center position-relative p-16px mt-8px" >
								<Input.Text
									id="inputTypePleadingA"
									name="text"
									src="@database.contract.part_1"
								size="md"
									className="w-100 min-h-100 fg-100 border-white position-relative" />
							</Group>
						</Group>
						<Group
							id="entityPartWrapper"
							direction="vertical"
							className="w-100 h-auto position-relative mt-24px" >
							<Text
								id="pleadingLabelB"
								className="w-100 h-auto position-relative ff-regular" >
								{t('build_parts_pleading.pleadingLabelB')}
							</Text>
							<Group
								id="pleadingRowB"
								direction="vertical"
								className="w-100 h-auto border-top border-bottom border-start border-end align-center position-relative p-16px mt-8px" >
								<Input.Text
									id="inputTypePleadingB"
									name="text"
									src="@database.contract.part_2"
								size="md"
									className="w-100 min-h-100 fg-100 border-white position-relative" />
							</Group>
						</Group>
					</Group>
					<Text
						id="continueButtonType1682873855582"
						onClick={continueButtonType1682873855582OnClick}
						className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mt-48px" >
						{t('build_parts_pleading.continueButtonType1682873855582')}
					</Text>
					<Text
						id="stepSampleType1682873855582"
						className="w-100 h-auto align-center position-relative mt-48px text-secondary-text ff-titles text-center" >
						{t('build_parts_pleading.stepSampleType1682873855582')}
					</Text>
				</Group>
				<Group
					id="bodySpacerBottom1682873855582"
					direction="vertical"
					className="w-100 h-fill position-relative" >
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
