import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Icon, Image, Text, Lottie } from '../components';
import { useEffect } from 'react';
import progress from '../assets/animations/progress.json';
import type_assesment from '../assets/images/type_assesment.jpg';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const DocumentView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const documentParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( documentParams, [])
	const documentOnLoaded = (options) => {
		run([{"id":"goto1683155746929","function":"goto","view":"dashboard","if":{"what":"@property.documentID","is":null}}], options)
	}
	useEffect(documentOnLoaded, [])
	const continueButtonType1683503204938OnClick = (options) => {
		run([{"id":"do1683503204938","function":"do","what":[{"id":"set1683503204938","function":"set","what":"@property.showCurtain","value":true},{"id":"add1683503204938","function":"add","what":{"created_at":"@cookie.timestamp","user_id":"@firebase.user.id","doc_content":"@property.document.doc_content"},"to":"@firebase.firestore.pdfs","key":"@firebase.user.id"},{"id":"set1683503204938","function":"set","what":"@property.pdfID","value":"@firebase.last.id"},{"id":"api1683503204938","function":"api","api":{"method":"doppio.pdf.sync","url":"https:\/\/aidocumenter.com\/pdfgeneration?id=(@firebase.user.id)"},"into":"@cookie.response","onsuccess":[{"id":"set1683503204938","function":"set","what":"@property.showCurtain","value":false},{"id":"set1683503204938","function":"set","what":"@cookie.url","value":"@cookie.response.documentUrl"},{"id":"download1683503204938","function":"download","url":"@cookie.url","name":"document.pdf"}],"onerror":[{"id":"set1683503204938","function":"set","what":"@property.showCurtain","value":false},{"id":"set1683503204938","function":"set","what":"@property.error","value":"pdf_error"},{"id":"popup1683503204938","function":"popup","dialog":"generic_error"}]}],"if":{"what":"@property.premium","is":true}},{"id":"do1683503204938","function":"do","what":[{"id":"popup1683503204938","function":"popup","dialog":"must_be_premium"}],"if":{"what":"@property.premium","is_not":true}}], options)
	}
	const continueButtonType1683153970832OnClick = (options) => {
		run([{"id":"do1683497199419","function":"do","what":[{"id":"set1683497199419","function":"set","what":"@property.showCurtain","value":true},{"id":"add1683497199419","function":"add","what":{"created_at":"@cookie.timestamp","user_id":"@firebase.user.id","doc_content":"@property.document.doc_content"},"to":"@firebase.firestore.pdfs","key":"@firebase.user.id"},{"id":"set1683497199419","function":"set","what":"@property.pdfID","value":"@firebase.last.id"},{"id":"api1683497199419","function":"api","api":{"method":"doppio.pdf.sync","url":"https:\/\/aidocumenter.com\/pdfgeneration?id=(@firebase.user.id)"},"into":"@cookie.response","onsuccess":[{"id":"set1683497199419","function":"set","what":"@property.showCurtain","value":false},{"id":"set1683497199419","function":"set","what":"@cookie.url","value":"@cookie.response.documentUrl"},{"id":"download1683497199419","function":"download","url":"@cookie.url","name":"document.pdf"}],"onerror":[{"id":"set1683497199419","function":"set","what":"@property.showCurtain","value":false},{"id":"set1683497199419","function":"set","what":"@property.error","value":"pdf_error"},{"id":"popup1683497199419","function":"popup","dialog":"generic_error"}]}],"if":{"what":"@property.premium","is":true}},{"id":"do1683497199419","function":"do","what":[{"id":"popup1683497199419","function":"popup","dialog":"must_be_premium"}],"if":{"what":"@property.premium","is_not":true}}], options)
	}
	const icon1683159511206OnClick = (options) => {
		run([{"id":"goto1683159511206","function":"goto","view":"dashboard"}], options)
	}
	const text1683153970832OnClick = (options) => {
		run([{"id":"goto1683153970832","function":"goto","view":"auth_logout"}], options)
	}
	const headerLogo1683153970832OnClick = (options) => {
		run([{"id":"goto1683157795113","function":"goto","view":"dashboard"}], options)
	}
	const menuToggler1683153970832OnClick = (options) => {
		run([{"id":"set1683153970832","function":"set","what":"@cookie.fullMenu","value":true,"if":{"what":"@cookie.fullMenu","is":false,"else":[{"id":"set1683153970832","function":"set","what":"@cookie.fullMenu","value":false}]}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('document.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="dashboardBody1683153970833"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="dashboardHeader1683153970832"
					direction="horizontal"
					className="w-100 h-auto position-relative p-32px" >
					<Icon
						id="menuToggler1683153970832"
						if={{"what":"@property.showMenu","is":true}}
						onClick={menuToggler1683153970832OnClick}
						src="menu"
						size={3}
						className="w-auto h-auto position-relative"
					/>
					<Image
						id="headerLogo1683153970832"
						onClick={headerLogo1683153970832OnClick}
						src={logo}
						className="w-192px h-auto valign-center position-relative ms-24px" />
					<Group
						id="group1683153970832"
						direction="vertical"
						className="w-fill h-auto position-relative" >
					</Group>
					<Text
						id="text1683153970832"
						onClick={text1683153970832OnClick}
						className="w-auto h-auto valign-center position-relative ff-buttons" >
						{t('document.text1683153970832')}
					</Text>
				</Group>
				<Group
					id="dashboardArea1683153970833"
					direction="horizontal"
					className="w-100 h-fill position-relative bg-lightest" >
					<Group
						id="sidemenuWrapper1683153970833"
						direction="vertical"
						className="w-auto min-h-100 h-100 position-relative bg-primary-lightest p-16px" >
						<Group
							id="group1683159501618"
							direction="vertical"
							className="w-fill h-auto position-relative" >
							<Icon
								id="icon1683159511206"
								onClick={icon1683159511206OnClick}
								src="arrow-left-bold"
								size={2}
								className="w-auto h-auto valign-center position-relative"
							/>
						</Group>
						<Group
							id="typeCell1683154402084"
							direction="vertical"
							className="max-w-300px w-auto h-auto border-top border-bottom border-start border-end position-relative bg-white mt-24px" >
							<Image
								id="typeImage1683154402084"
								fallback={type_assesment}
								src="@database.types.(@field.type).image"
								className="w-100 h-auto position-relative" />
							<Text
								id="docType"
								tag="small"
								src="@database.types.(@property.document.type).title.(@cookie.lang)"
								className="w-100 h-auto position-relative px-16px pb-16px text-accent ff-regular" >
								{t('document.docType')}
							</Text>
							<Text
								id="docDescription"
								tag="small"
								src="@property.document.description"
								className="w-100 h-auto position-relative px-16px pb-16px text-secondary-text ff-regular" >
								{t('document.docDescription')}
							</Text>
						</Group>
					</Group>
					<Group
						id="group1683153970833"
						direction="vertical"
						className="w-fill min-h-100 h-100 position-relative bg-gray p-48px scrollable" >
						<Group
							id="previewContent1683154728543"
							direction="vertical"
							className="w-80 h-auto border-radius-20px align-center position-relative bg-white p-32px" >
							<Text
								id="textPreview1683154728543"
								format={{"type":"html"}}
								src="@property.document.doc_content"
								className="w-100 h-auto align-center position-relative ff-regular" >
								{t('document.textPreview1683154728543')}
							</Text>
						</Group>
					</Group>
				</Group>
				<Group
					id="dashboardSubheader1683153970832"
					direction="horizontal"
					className="w-100 h-auto position-relative bg-light px-32px py-24px" >
					<Text
						id="stepTitleLang1683153970832"
						src="@property.document.name"
						className="w-auto h-auto align-center valign-center position-relative pt-6px ms-16px text-primary ff-titles text-start" >
						{t('document.stepTitleLang1683153970832')}
					</Text>
					<Group
						id="group1683153970832"
						direction="vertical"
						className="w-fill h-auto position-relative" >
					</Group>
					<Group
						id="newDocButton1683153970832"
						direction="horizontal"
						className="w-auto h-auto position-relative bg-text-on-primary p-1px" >
						<Text
							id="continueButtonType1683153970832"
							onClick={continueButtonType1683153970832OnClick}
							className="w-auto h-auto align-center position-relative bg-primary-light px-48px py-16px me-24px text-text-on-primary" >
							{t('document.continueButtonType1683153970832')}
						</Text>
						<Text
							id="continueButtonType1683503204938"
							onClick={continueButtonType1683503204938OnClick}
							className="w-auto h-auto align-center position-relative bg-primary-light px-48px py-16px text-text-on-primary" >
							{t('document.continueButtonType1683503204938')}
						</Text>
					</Group>
				</Group>
			</Group>
			<Group
				id="previewCurtain1683500352167"
				if={{"what":"@property.showCurtain","is":true}}
				direction="horizontal"
				className="w-100 min-h-100vh h-100vh position-absolute bg-lighten" >
				<Group
					id="contentBuilding1683500352167"
					direction="vertical"
					className="w-100 h-auto valign-center position-relative" >
					<Text
						id="stepTitleLang1683500352167"
						tag="h1"
						className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
						{t('document.stepTitleLang1683500352167')}
					</Text>
					<Lottie
						id="lottie1683500352167"
						src={progress}
						className="w-128px min-h-128px h-128px align-center position-relative m-48px" />
					<Text
						id="stepStep1683500352167"
						className="w-100 h-auto align-center position-relative mt-24px text-accent ff-titles text-center" >
						{t('document.stepStep1683500352167')}
					</Text>
					<Text
						id="stepInstructionsType1683500352167"
						className="w-100 h-auto align-center position-relative mt-6px text-primary-text ff-titles text-center" >
						{t('document.stepInstructionsType1683500352167')}
					</Text>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
