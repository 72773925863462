import React, { createContext, useEffect, useState } from 'react';

import { useImmerReducer } from 'use-immer';
import { parse } from '../util';
import { ApiReducer, API } from './reducers';

export const ApiContext = createContext({});

export const ApiProvider = (props) => {
  const [state, dispatch] = useImmerReducer(
    ApiReducer,
    API.INITIAL_STATE
  );

    const getFromApi = async ({ provider, method, parameters }) => {
        const response = await fetch(`https://api.doyo.tech`, {
          method: 'POST',
          body: JSON.stringify({
            provider,
            method,
            parameters,
          }),
          headers: {
            Authorization: 'Bearer 147|ZLvEmWuEuKMgdCueXmOiyAMGFLVv9Flg3hpZ3S5A',
            'Content-Type': 'application/json'
          }
        });
        const { result } = await response.json();
        dispatch({ type: API.GET, payload: { key: `${provider}.${method}`, value: result } });
        return result;
    }

  return (
    <ApiContext.Provider value={{ 
      api: state,
      getFromApi,
    }}>
      {props.children}
    </ApiContext.Provider>
  );
};

export const ApiConsumer = ApiContext.Consumer;
export default ApiContext;
