import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group } from '../components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const CheckoutView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const checkoutParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( checkoutParams, [])
	const checkoutOnLoaded = (options) => {
		run([{"id":"set1683412920524","function":"set","what":"@cookie.url","value":null},{"id":"api1683412572988","function":"api","api":{"method":"stripe.checkout.product","mode":"subscription","success_url":"https:\/\/aidocumenter.com\/purchase","price_id":"@database.products.premium.stripe_price_id","customer_email":"@firebase.user.email","cancel_url":"https:\/\/aidocumenter.com\/premium","allow_promotion_codes":true},"into":"@cookie.checkout","onsuccess":[{"id":"browser1683416493937","function":"browser","url":"@cookie.checkout.url"}]}], options)
	}
	useEffect(checkoutOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('checkout.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="group1683495876559"
				direction="vertical"
				className="w-100 h-auto position-relative" >
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
