import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Generic_errorDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const generic_error_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"generic_error"}], options)
	}

  return (
    
    
			<Modal id="generic_error">
					<Modal.Header>
						<Modal.Title>{t('generic_error.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="verification_error"
							if={{"what":"@property.error","is":"verification_error"}}
							className="w-100 h-auto position-relative" >
							{t('generic_error.verification_error')}
						</Text>
						<Text
							id="signup_error"
							if={{"what":"@property.error","is":"signup_error"}}
							className="w-100 h-auto position-relative" >
							{t('generic_error.signup_error')}
						</Text>
						<Text
							id="signup_exists"
							if={{"what":"@property.error","is":"signup_exists"}}
							className="w-100 h-auto position-relative" >
							{t('generic_error.signup_exists')}
						</Text>
						<Text
							id="signup_weak"
							if={{"what":"@property.error","is":"signup_weak"}}
							className="w-100 h-auto position-relative" >
							{t('generic_error.signup_weak')}
						</Text>
						<Text
							id="verification_not_sent"
							if={{"what":"@property.error","is":"verification_not_sent"}}
							className="w-100 h-auto position-relative" >
							{t('generic_error.verification_not_sent')}
						</Text>
						<Text
							id="pdf_error"
							if={{"what":"@property.error","is":"verification_not_sent"}}
							className="w-100 h-auto position-relative" >
							{t('generic_error.pdf_error')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="generic_error_buttons_positive"
							onClick={generic_error_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('generic_error.generic_error_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Generic_errorDialog;
