import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input } from '../components';
import { useEffect } from 'react';
import auth_photo from '../assets/images/auth_photo.jpg';
import auth_bg from '../assets/images/auth_bg.jpg';
import auth_google from '../assets/images/auth_google.svg';
import icon_144 from '../assets/icon/icon_144.png';
import { useTranslation } from 'react-i18next';

export const AuthView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const authParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( authParams, [])
	const authOnLoaded = (options) => {
		run([{"function":"goto","view":"auth_destination","if":{"what":"@firebase.user.id","is_not":null,"else":[{"function":"set","what":"@property.authForm","value":"signin"}],"and":{"what":"@property.exiting","is_not":true}}},{"id":"set1683502743023","function":"set","what":"@property.exiting","value":false}], options)
	}
	useEffect(authOnLoaded, [])
	const showSigninGroupLinkOnClick = (options) => {
		run([{"function":"set","what":"@property.authForm","value":"signin"}], options)
	}
	const googleSignupIconOnClick = (options) => {
		run([{"function":"login","provider":"firebase.google","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"add","what":{"verified":true,"email":"@firebase.user.email","signin_method":"google","version":2},"to":"@firebase.firestore.users","key":"@cookie.userID"},{"function":"set","what":"@property.verified","value":true},{"function":"goto","view":"access"}],"oninvalid":[{"function":"set","what":"@property.msg","value":"login_error"},{"function":"popup","dialog":"msg_dialog"}]}], options)
	}
	const authSignupGoogleOnClick = (options) => {
		run([{"function":"login","provider":"firebase.google","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"add","what":{"name":"@property.authName"},"to":"@firebase.firestore.users","key":"@cookie.userID"},{"function":"goto","view":"auth_destination"}],"oninvalid":[{"function":"set","what":"@property.authMessage","value":"google"},{"function":"popup","dialog":"auth_dialog"}],"if":{"what":"@property.authTermsAccepted","is":true,"else":[{"function":"set","what":"@property.authMessage","value":"terms"},{"function":"popup","dialog":"auth_dialog"}]}}], options)
	}
	const signupButtonOnClick = (options) => {
		run([{"function":"signup","provider":"firebase","email":"@property.authEmail","pass":"@property.authPassword","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"add","what":{"name":"@property.authName"},"to":"@firebase.firestore.users","key":"@cookie.userID"},{"function":"goto","view":"auth_destination"}],"if":{"what":"@cookie.authTermsAccepted","is":true,"else":[{"function":"set","what":"@property.authMessage","value":"terms"},{"function":"popup","dialog":"auth_dialog"}]},"onexists":[{"function":"set","what":"@property.authMessage","value":"exists"},{"function":"popup","dialog":"auth_dialog"}],"oninvalid":[{"function":"set","what":"@property.authMessage","value":"signup"},{"function":"popup","dialog":"auth_dialog"}],"onweakpassword":[{"function":"set","what":"@property.authMessage","value":"weak"},{"function":"popup","dialog":"auth_dialog"}]}], options)
	}
	const authTermsLinkOnClick = (options) => {
		run([{"function":"popup","dialog":"auth_terms"}], options)
	}
	const showSignupGroupLinkOnClick = (options) => {
		run([{"function":"set","what":"@property.authForm","value":"signup"}], options)
	}
	const loginRecoverLinkOnClick = (options) => {
		run([{"function":"set","what":"@property.authMessage","value":"forgot"},{"function":"popup","dialog":"auth_dialog"}], options)
	}
	const loginButtonOnClick = (options) => {
		run([{"function":"login","provider":"firebase.email","email":"@property.authEmail","pass":"@property.authPassword","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"goto","view":"auth_destination"}],"oninvalid":[{"function":"set","what":"@property.authMessage","value":"signin"},{"function":"popup","dialog":"auth_dialog"}]}], options)
	}
	const googleSigninButtonOnClick = (options) => {
		run([{"function":"login","provider":"firebase.google","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"add","what":{"verified":true,"email":"@firebase.user.email","signin_method":"google","version":2},"to":"@firebase.firestore.users","key":"@cookie.userID"},{"function":"set","what":"@property.verified","value":true},{"function":"goto","view":"access"}],"oninvalid":[{"function":"set","what":"@property.msg","value":"login_error"},{"function":"popup","dialog":"msg_dialog"}]}], options)
	}
	const authSigninGoogleOnClick = (options) => {
		run([{"function":"login","provider":"firebase.google","onvalid":[{"function":"set","what":"@cookie.userID","value":"@firebase.user.id"},{"function":"goto","view":"auth_destination"}],"oninvalid":[{"function":"set","what":"@property.authMessage","value":"google"},{"function":"popup","dialog":"auth_dialog"}]}], options)
	}
	const authLogoOnClick = (options) => {
		run([{"id":"goto1683061085254","function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('auth.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
			<Group
				id="authBody"
				direction="auto"
				className="w-100 min-h-100vh h-100vh position-relative" >
				<Group
					id="authWrapper"
					direction="vertical"
					className="min-w-320px w-fill min-h-100 h-100 position-relative py-32px" >
					<Group
						id="authArea"
						direction="vertical"
						className="w-100 h-fill border-radius-tr50px-br50px position-relative bg-background" >
						<Group
							id="authForms"
							direction="vertical"
							className="max-w-400px w-fill h-auto align-center valign-center position-absolute" >
							<Group
								id="signinForm"
								if={{"what":"@property.authForm","is_not":"signup"}}
								direction="vertical"
								className="w-100 h-auto border-radius-15px position-relative p-32px mt-24px" >
								<Image
									id="authLogo"
									onClick={authLogoOnClick}
									src={icon_144}
									className="w-144px h-auto align-center position-relative" />
								<Group
									id="authSigninGoogle"
									onClick={authSigninGoogleOnClick}
									direction="vertical"
									className="w-100 h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-8px mt-24px" >
									<Group
										id="authSigninGoogleContent"
										direction="horizontal"
										className="w-auto h-auto align-center position-relative" >
										<Image
											id="googleSigninButton"
											onClick={googleSigninButtonOnClick}
											src={auth_google}
											className="w-24px min-h-24px h-24px valign-center position-relative bg-white" />
										<Text
											id="googleSigninLabel"
											className="w-auto h-auto valign-center position-relative ps-16px text-primary-text ff-auth" >
											{t('auth.googleSigninLabel')}
										</Text>
									</Group>
								</Group>
								<Text
									id="authOrLabel"
									className="w-auto h-auto align-center position-relative py-12px text-primary-dark ff-auth" >
									{t('auth.authOrLabel')}
								</Text>
								<Group
									id="authSigninEmail"
									direction="vertical"
									className="w-100 h-auto position-relative" >
									<Input.Email
										id="authLoginEmailInput"
										name="loginEmail"
										src="@property.authEmail"
									placeholder={t('auth.authLoginEmailInput.hint')}
										className="w-100 h-auto position-relative bg-primary-light" />
									<Input.Password
										id="authLoginPasswordInput"
										name="loginEmail"
										src="@property.authPassword"
									placeholder={t('auth.authLoginPasswordInput.hint')}
										className="w-100 h-auto position-relative bg-primary-light mt-24px" />
								</Group>
								<Text
									id="loginButton"
									onClick={loginButtonOnClick}
									className="w-100 h-auto border-radius-10px position-relative bg-primary py-8px mt-32px text-text-on-primary ff-auth text-center" >
									{t('auth.loginButton')}
								</Text>
								<Text
									id="loginRecoverLink"
									onClick={loginRecoverLinkOnClick}
									className="w-auto h-auto align-center position-relative mt-16px text-primary ff-auth" >
									{t('auth.loginRecoverLink')}
								</Text>
								<Group
									id="showSignupGroup"
									direction="horizontal"
									className="w-auto h-auto align-center position-relative p-8px mt-16px" >
									<Text
										id="showSignupGroupLabel"
										className="w-auto h-auto position-relative pe-8px text-primary-text ff-auth" >
										{t('auth.showSignupGroupLabel')}
									</Text>
									<Text
										id="showSignupGroupLink"
										onClick={showSignupGroupLinkOnClick}
										className="w-auto h-auto position-relative text-primary ff-auth" >
										{t('auth.showSignupGroupLink')}
									</Text>
								</Group>
							</Group>
							<Group
								id="signupForm"
								if={{"what":"@property.authForm","is":"signup"}}
								direction="vertical"
								className="w-100 h-auto border-radius-15px position-relative p-32px mt-24px" >
								<Text
									id="signupSlogan"
									tag="h3"
									className="w-auto h-auto align-center position-relative py-12px text-primary-dark ff-auth text-center" >
									{t('auth.signupSlogan')}
								</Text>
								<Group
									id="authSignupEmail"
									direction="vertical"
									className="w-100 h-auto position-relative mt-24px" >
									<Input.Text
										id="authSignupNameInput"
										name="signupName"
										src="@property.authName"
									placeholder={t('auth.authSignupNameInput.hint')}
										className="w-100 h-auto position-relative bg-primary-light" />
									<Input.Email
										id="authSignupEmailInput"
										name="signupEmail"
										src="@property.authEmail"
									placeholder={t('auth.authSignupEmailInput.hint')}
										className="w-100 h-auto position-relative bg-primary-light mt-24px" />
									<Input.Password
										id="authSignupPasswordInput"
										name="loginEmail"
										src="@property.authPassword"
									placeholder={t('auth.authSignupPasswordInput.hint')}
										className="w-100 h-auto position-relative bg-primary-light mt-24px" />
								</Group>
								<Group
									id="authTermsGroup"
									direction="horizontal"
									className="w-auto h-auto align-center position-relative p-8px mt-16px" >
									<Input.Switch
										id="authTermsSwitch"
										name="switch"
										src="@cookie.authTermsAccepted"
										className="w-auto h-auto position-relative" />
									<Text
										id="authTermsLabel"
										className="w-auto h-auto position-relative pe-4px text-primary-text ff-auth" >
										{t('auth.authTermsLabel')}
									</Text>
									<Text
										id="authTermsLink"
										onClick={authTermsLinkOnClick}
										className="w-auto h-auto position-relative pe-4px text-primary ff-auth" >
										{t('auth.authTermsLink')}
									</Text>
								</Group>
								<Text
									id="signupButton"
									onClick={signupButtonOnClick}
									className="w-100 h-auto border-radius-10px position-relative bg-primary py-8px mt-16px text-text-on-primary ff-auth text-center" >
									{t('auth.signupButton')}
								</Text>
								<Text
									id="authOrLabelSignup"
									className="w-auto h-auto align-center position-relative py-12px text-primary-dark ff-auth" >
									{t('auth.authOrLabelSignup')}
								</Text>
								<Group
									id="authSignupGoogle"
									onClick={authSignupGoogleOnClick}
									direction="vertical"
									className="w-100 h-auto border-top border-bottom border-start border-end border-radius-10px position-relative p-8px" >
									<Group
										id="authSignupGoogleContent"
										direction="horizontal"
										className="w-auto h-auto align-center position-relative" >
										<Image
											id="googleSignupIcon"
											onClick={googleSignupIconOnClick}
											src={auth_google}
											className="w-24px min-h-24px h-24px valign-center position-relative bg-white" />
										<Text
											id="googleSignupLabel"
											className="w-auto h-auto valign-center position-relative ps-16px text-primary-text ff-auth" >
											{t('auth.googleSignupLabel')}
										</Text>
									</Group>
								</Group>
								<Group
									id="showSigninGroup"
									direction="horizontal"
									className="w-auto h-auto align-center position-relative p-8px mt-16px" >
									<Text
										id="showSigninGroupLabel"
										className="w-auto h-auto position-relative pe-8px text-primary-text ff-auth" >
										{t('auth.showSigninGroupLabel')}
									</Text>
									<Text
										id="showSigninGroupLink"
										onClick={showSigninGroupLinkOnClick}
										className="w-auto h-auto position-relative text-primary ff-auth" >
										{t('auth.showSigninGroupLink')}
									</Text>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="authTestimonials"
					direction="vertical"
					className="min-w-320px w-fill min-h-100 h-100 position-relative" >
					<Image
						id="authBackgroundImage"
						src={auth_bg}
						className="w-100 min-h-100 fg-100 position-absolute cover" />
					<Group
						id="authImageDarken"
						direction="vertical"
						className="w-100 min-h-100 fg-100 position-absolute bg-primary-darken" >
					</Group>
					<Group
						id="authTestimonialWrapper"
						direction="vertical"
						className="max-w-300px w-fill h-auto align-center valign-center position-absolute" >
						<Image
							id="authTestimonialPhoto"
							src={auth_photo}
							className="w-256px min-h-256px h-256px border-top border-bottom border-start border-end border-radius-200px align-center position-relative" />
						<Text
							id="authTestimonialQuote"
							tag="h3"
							className="w-100 h-auto position-relative mt-32px text-white ff-titles text-center" >
							{t('auth.authTestimonialQuote')}
						</Text>
						<Text
							id="authTestimonialAuthor"
							className="w-100 h-auto position-relative mt-32px text-primary-light ff-auth text-center" >
							{t('auth.authTestimonialAuthor')}
						</Text>
					</Group>
				</Group>
			</Group>
      </Auth_fullscreenWrapper>
    </>
  );
};
