import React from 'react';
import { useFunctions } from '../hooks';

// Custom imports:
import { Offcanvas, Web } from '../components';

export const Auth_termsDialog = () => {
    const { run } = useFunctions();
  // Custom exports:

  // Custom functions:

  return (
    
    
			<Offcanvas id="auth_terms" placement="end" backdrop={true}>
					<Offcanvas.Body>
						<Web
							id="policyContent"
							src="https://termly.io/resources/templates/terms-and-conditions-template/"
							className="w-100 min-h-100 fg-100 position-relative" />
					</Offcanvas.Body>
			</Offcanvas>
    
  );
};

export default Auth_termsDialog;
