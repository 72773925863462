import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Lottie } from '../components';
import { useEffect } from 'react';
import ic_internet from '../assets/iconset/ic_internet.svg';
import ic_pdf from '../assets/iconset/ic_pdf.svg';
import ic_free from '../assets/iconset/ic_free.svg';
import highlight from '../assets/images/highlight.jpg';
import home_polygon_bottom from '../assets/images/home_polygon_bottom.svg';
import home_polygon from '../assets/images/home_polygon.svg';
import scrolldownblue_fe6fdf19e178347fc3b6cda7e6635f24 from '../assets/animations/scrolldownblue_fe6fdf19e178347fc3b6cda7e6635f24.json';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const HomeView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( homeParams, [])
	const homeOnLoaded = (options) => {
		run([{"id":"set1683111420396","function":"set","what":"@cookie.lang","value":"es","if":{"what":"@app.language","is":"es","else":[{"id":"set1683111420396","function":"set","what":"@cookie.lang","value":"en"}]}}], options)
	}
	useEffect(homeOnLoaded, [])
	const copyrightLabel1682927813292OnClick = (options) => {
		run([{"id":"browser1682927843227","function":"browser","url":"https:\/\/doyo.tech","target":"_doyo"}], options)
	}
	const homeStartButtonOnClick = (options) => {
		run([{"id":"goto1682811806361","function":"goto","view":"build_type"}], options)
	}
	const text1683047223778OnClick = (options) => {
		run([{"id":"goto1683047377158","function":"goto","view":"auth"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('home.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="homeBody"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative scrollable" >
				<Group
					id="homeMainArea"
					direction="vertical"
					className="w-100 min-h-100vh h-auto position-relative" >
					<Group
						id="homeSpacerTop"
						direction="vertical"
						className="w-100 h-fill position-relative" >
						<Group
							id="homeHeader"
							direction="horizontal"
							className="w-100 h-auto position-relative p-32px" >
							<Image
								id="headerLogo"
								src={logo}
								className="w-192px h-auto position-relative" />
							<Group
								id="group1683047210098"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
							<Text
								id="text1683047223778"
								onClick={text1683047223778OnClick}
								className="w-auto h-auto valign-center position-relative ff-buttons" >
								{t('home.text1683047223778')}
							</Text>
						</Group>
					</Group>
					<Group
						id="startArea"
						direction="vertical"
						className="max-w-1000px w-100 h-auto align-center position-relative p-32px" >
						<Text
							id="homeSlogan"
							tag="h1"
							className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
							{t('home.homeSlogan')}
						</Text>
						<Text
							id="homeSloganSubtitle"
							className="w-100 h-auto align-center position-relative mt-24px text-primary-text ff-titles text-center" >
							{t('home.homeSloganSubtitle')}
						</Text>
						<Text
							id="homeStartButton"
							onClick={homeStartButtonOnClick}
							className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mt-48px" >
							{t('home.homeStartButton')}
						</Text>
					</Group>
					<Group
						id="homeSpacerBottom"
						direction="vertical"
						className="w-100 h-fill position-relative" >
					</Group>
					<Lottie
						id="scrollDownAnimation"
						src={scrolldownblue_fe6fdf19e178347fc3b6cda7e6635f24}
						className="w-64px h-auto align-center valign-end position-absolute-bottom m-16px" />
				</Group>
				<Group
					id="homeAbout"
					direction="vertical"
					className="w-100 h-auto position-relative bg-primary p-32px" >
					<Text
						id="text1682894847608"
						className="max-w-1000px w-100 h-auto align-center position-relative text-text-on-primary ff-regular text-center" >
						{t('home.text1682894847608')}
					</Text>
				</Group>
				<Group
					id="homeContent"
					direction="vertical"
					className="w-100 h-auto position-relative" >
					<Group
						id="homePromo"
						direction="vertical"
						className="w-100 h-auto position-relative mt-64px" >
						<Group
							id="group1683287003095"
							direction="vertical"
							className="w-100 min-h-100 fg-100 position-absolute" >
							<Image
								id="image1683286400025"
								src={home_polygon}
								className="w-100 h-auto position-relative" />
							<Group
								id="group1683287033260"
								direction="vertical"
								className="w-100 h-fill position-relative bg-grey_fifty" >
							</Group>
							<Image
								id="image1683286468560"
								src={home_polygon_bottom}
								className="w-100 h-auto position-relative" />
						</Group>
						<Group
							id="group1683286449860"
							direction="vertical"
							className="w-50 h-auto align-center position-relative p-48px" >
							<Group
								id="group1683286916032"
								direction="vertical"
								className="w-100 h-auto position-relative bg-white p-10px" >
								<Image
									id="image1683286850616"
									src={highlight}
									className="w-100 h-auto position-relative" />
								<Text
									id="homeSlogan1683287150958"
									tag="h1"
									className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
									{t('home.homeSlogan1683287150958')}
								</Text>
								<Text
									id="homeSlogan1683287276085"
									className="w-100 h-auto align-center position-relative mt-16px text-primary-text ff-regular text-center" >
									{t('home.homeSlogan1683287276085')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="groupFeatures"
						direction="vertical"
						className="w-100 h-auto position-relative mt-128px" >
						<Group
							id="groupFeaturesWrapper"
							direction="auto"
							className="max-w-1200px w-90 h-auto align-center position-relative" >
							<Group
								id="feature1Group"
								direction="vertical"
								className="w-fill h-auto position-relative p-36px" >
								<Image
									id="image1683287662877"
									src={ic_free}
									className="w-auto min-h-120px h-120px align-center position-relative" />
								<Text
									id="featureTitle1"
									tag="h2"
									className="w-100 h-auto align-center position-relative mt-24px text-primary ff-titles text-center" >
									{t('home.featureTitle1')}
								</Text>
								<Text
									id="featureDescription1"
									className="w-100 h-auto align-center position-relative mt-16px text-primary-text ff-regular text-center" >
									{t('home.featureDescription1')}
								</Text>
							</Group>
							<Group
								id="feature1Group1683287955104"
								direction="vertical"
								className="w-fill h-auto position-relative p-36px" >
								<Image
									id="image1683287955104"
									src={ic_pdf}
									className="w-auto min-h-120px h-120px align-center position-relative p-12px" />
								<Text
									id="featureTitle2"
									tag="h2"
									className="max-w-100 w-100 h-auto align-center position-relative mt-24px text-primary ff-titles text-center" >
									{t('home.featureTitle2')}
								</Text>
								<Text
									id="featureDescription2"
									className="w-100 h-auto align-center position-relative mt-16px text-primary-text ff-regular text-center" >
									{t('home.featureDescription2')}
								</Text>
							</Group>
							<Group
								id="feature1Group1683287978019"
								direction="vertical"
								className="w-fill h-auto position-relative p-36px" >
								<Image
									id="image1683287978019"
									src={ic_internet}
									className="w-auto min-h-120px h-120px align-center position-relative" />
								<Text
									id="featureTitle3"
									tag="h2"
									className="w-100 h-auto align-center position-relative m-24px text-primary ff-titles text-center" >
									{t('home.featureTitle3')}
								</Text>
								<Text
									id="featureDescription3"
									className="w-100 h-auto align-center position-relative mt-16px text-primary-text ff-regular text-center" >
									{t('home.featureDescription3')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="homePlans"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary-lightest p-64px mt-128px" >
						<Text
							id="homeSlogan1683288337434"
							tag="h1"
							className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
							{t('home.homeSlogan1683288337434')}
						</Text>
						<Group
							id="plansWrapper"
							direction="auto"
							className="max-w-1200px w-90 h-auto align-center position-relative mt-48px" >
							<Group
								id="planWrapper_1"
								direction="vertical"
								className="min-w-250px w-fill h-auto position-relative p-48px" >
								<Text
									id="text1683288472347"
									tag="h3"
									className="w-100 h-auto position-relative bg-primary p-16px text-text-on-primary ff-titles text-center" >
									{t('home.text1683288472347')}
								</Text>
								<Group
									id="planFeatures_1"
									direction="vertical"
									className="w-100 h-auto position-relative bg-text-on-primary" >
									<Text
										id="text1683288569193"
										className="w-100 h-auto position-relative p-16px text-primary-text ff-regular text-center" >
										{t('home.text1683288569193')}
									</Text>
									<Text
										id="text1683288629803"
										className="w-100 h-auto position-relative p-16px text-primary-text ff-regular text-center" >
										{t('home.text1683288629803')}
									</Text>
								</Group>
							</Group>
							<Group
								id="planWrapper_1683288680974"
								direction="vertical"
								className="min-w-250px w-fill h-auto position-relative p-48px" >
								<Text
									id="text1683288680974"
									tag="h3"
									className="w-100 h-auto position-relative bg-primary p-16px text-text-on-primary ff-titles text-center" >
									{t('home.text1683288680974')}
								</Text>
								<Group
									id="planFeatures_1683288680974"
									direction="vertical"
									className="w-100 h-auto position-relative bg-text-on-primary" >
									<Text
										id="featurePaid1"
										className="w-100 h-auto position-relative p-16px text-primary-text ff-regular text-center" >
										{t('home.featurePaid1')}
									</Text>
									<Text
										id="featurePaid2"
										className="w-100 h-auto position-relative p-16px text-primary-text ff-regular text-center" >
										{t('home.featurePaid2')}
									</Text>
									<Text
										id="featurePaid3"
										className="w-100 h-auto position-relative p-16px text-primary-text ff-regular text-center" >
										{t('home.featurePaid3')}
									</Text>
									<Text
										id="featurePaid1683289937933"
										className="w-100 h-auto position-relative p-16px text-primary-text ff-regular text-center" >
										{t('home.featurePaid1683289937933')}
									</Text>
								</Group>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeFooter"
					direction="auto"
					className="w-100 h-auto position-relative bg-primary p-24px" >
					<Group
						id="homeCopyrightArea"
						direction="vertical"
						className="w-fill h-auto position-relative" >
						<Text
							id="copyrightLabel"
							className="w-100 h-auto position-relative text-text-on-primary ff-regular" >
							{t('home.copyrightLabel')}
						</Text>
					</Group>
					<Group
						id="homeDoyoInfo"
						direction="vertical"
						className="min-w-300px w-fill h-auto position-relative" >
						<Text
							id="copyrightLabel1682927553328"
							className="w-100 h-auto position-relative text-text-on-primary ff-bold" >
							{t('home.copyrightLabel1682927553328')}
						</Text>
						<Text
							id="copyrightLabel1682927643133"
							className="w-100 h-auto position-relative mt-16px text-secondary-text ff-regular" >
							{t('home.copyrightLabel1682927643133')}
						</Text>
						<Text
							id="copyrightLabel1682927813292"
							onClick={copyrightLabel1682927813292OnClick}
							className="w-auto h-auto position-relative mt-16px text-secondary-text ff-regular" >
							{t('home.copyrightLabel1682927813292')}
						</Text>
					</Group>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
