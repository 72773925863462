import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Main_dialogDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const main_dialog_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"main_dialog"}], options)
	}

  return (
    
    
			<Modal id="main_dialog">
					<Modal.Header>
						<Modal.Title>{t('main_dialog.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{t('main_dialog.text')}
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="main_dialog_buttons_positive"
							onClick={main_dialog_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('main_dialog.main_dialog_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Main_dialogDialog;
