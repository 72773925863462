import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input } from '../components';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const Build_descriptionView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const continueButtonTypeOnClick = (options) => {
		run([{"id":"goto1682813268526","function":"goto","view":"build_lang"}], options)
	}
	const text1683149044103OnClick = (options) => {
		run([{"id":"goto1683149044103","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683149044103","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}
	const headerLogo1683104797740OnClick = (options) => {
		run([{"id":"goto1683149055613","function":"goto","view":"home","if":{"what":"@firebase.user.id","is":null},"comments":"No session"},{"id":"goto1683149055613","function":"goto","view":"dashboard","if":{"what":"@firebase.user.id","is_not":null},"comments":"No session"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('build_description.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="bodyBuildType"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="bodySpacerTop"
					direction="vertical"
					className="w-100 h-fill position-relative" >
					<Group
						id="homeHeader1683104797740"
						direction="horizontal"
						className="w-100 h-auto position-relative p-32px" >
						<Image
							id="headerLogo1683104797740"
							onClick={headerLogo1683104797740OnClick}
							src={logo}
							className="w-192px h-auto position-relative" />
						<Group
							id="group1683104797740"
							direction="vertical"
							className="w-fill h-auto position-relative" >
						</Group>
						<Text
							id="text1683149044103"
							onClick={text1683149044103OnClick}
							className="w-auto h-auto valign-center position-relative ff-buttons" >
							{t('build_description.text1683149044103')}
						</Text>
					</Group>
				</Group>
				<Group
					id="formWrapperType"
					direction="vertical"
					className="max-w-1000px w-100 h-auto align-center position-relative" >
					<Text
						id="stepTitleType"
						tag="h1"
						className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
						{t('build_description.stepTitleType')}
					</Text>
					<Text
						id="stepStep"
						className="w-100 h-auto align-center position-relative mt-24px text-accent ff-titles text-center" >
						{t('build_description.stepStep')}
					</Text>
					<Text
						id="stepInstructionsType"
						className="w-100 h-auto align-center position-relative mt-6px text-primary-text ff-titles text-center" >
						{t('build_description.stepInstructionsType')}
					</Text>
					<Group
						id="inputWrapper1682812082496"
						direction="vertical"
						className="w-80 h-auto border-top border-bottom border-start border-end align-center position-relative p-16px mt-32px" >
						<Input.Text
							id="inputType"
							name="text"
							src="@database.contract.description"
						size="md"
							className="w-100 min-h-100 fg-100 border-white position-relative" />
					</Group>
					<Text
						id="continueButtonType"
						onClick={continueButtonTypeOnClick}
						className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mt-48px" >
						{t('build_description.continueButtonType')}
					</Text>
					<Text
						id="stepSampleType"
						className="w-100 h-auto align-center position-relative mt-48px text-secondary-text ff-titles text-center" >
						{t('build_description.stepSampleType')}
					</Text>
				</Group>
				<Group
					id="bodySpacerBottom1682812082496"
					direction="vertical"
					className="w-100 h-fill position-relative" >
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
