import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Storage_limitDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const storage_limit_buttons_positiveOnClick = (options) => {
		run([{"id":"add1683491851934","function":"add","what":{"user_id":"@firebase.user.id","created_at":"@cookie.timestamp","name":"@property.name","doc_content":"@cookie.generation.doc_content","type":"@cookie.generation.type","part_1":"@cookie.generation.part_1","part_2":"@cookie.generation.part_2","part_3":"@cookie.generation.part_3","language":"@cookie.generation.language","country":"@cookie.generation.country","description":"@cookie.generation.description","information":"@cookie.generation.information"},"to":"@firebase.firestore.documents","key":"@firebase.user.id"},{"id":"goto1683491851934","function":"goto","view":"dashboard"},{"function":"hide","dialog":"storage_limit"}], options)
	}
	const storage_limit_buttons_negativeOnClick = (options) => {
		run([{"function":"hide","dialog":"storage_limit"}], options)
	}

  return (
    
    
			<Modal id="storage_limit" centered>
					<Modal.Header>
						<Modal.Title>{t('storage_limit.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="verification_error1683491647486"
							className="w-100 h-auto position-relative" >
							{t('storage_limit.verification_error1683491647486')}
						</Text>
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="storage_limit_buttons_negative"
							onClick={storage_limit_buttons_negativeOnClick}
							variant="transparent"
							className="w-auto h-auto position-relative" >
							{t('storage_limit.storage_limit_buttons_negative')}
						</Button>
						<Button
							id="storage_limit_buttons_positive"
							onClick={storage_limit_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('storage_limit.storage_limit_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Storage_limitDialog;
