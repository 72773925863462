import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Auth_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Auth_logoutView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const auth_logoutParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( auth_logoutParams, [])
	const auth_logoutOnLoaded = (options) => {
		run([{"function":"set","what":"@cookie.userID","value":null},{"function":"set","what":"@property.exiting","value":true},{"function":"logout","provider":"firebase"},{"id":"wait1683502669026","function":"wait","seconds":2},{"function":"goto","view":"auth"}], options)
	}
	useEffect(auth_logoutOnLoaded, [])

  return (
    <>
      <Helmet>
				<title>{t('auth_logout.title')}</title>
      </Helmet>
      <Auth_fullscreenWrapper>
          
      </Auth_fullscreenWrapper>
    </>
  );
};
