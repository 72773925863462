import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Input } from '../components';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const Email_verificationView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const continueButtonType1682955275857OnClick = (options) => {
		run([{"id":"set1682955275857","function":"set","what":"@property.valid","value":true},{"id":"do1682967665687","function":"do","if":{"what":"@property.verification","is_not":"@cookie.code"},"what":[{"id":"set1682967691682","function":"set","what":"@property.valid","value":false},{"id":"set1682968172085","function":"set","what":"@property.error","value":"verification_error"},{"id":"popup1682967714766","function":"popup","dialog":"generic_error"}],"comments":"Check verification code"},{"id":"do1682955275857","function":"do","what":[{"id":"set1683150046376","function":"set","what":"@property.isCreatingDocument","value":true},{"id":"signup1682956145086","function":"signup","provider":"firebase","email":"@cookie.email","pass":"@property.password","onvalid":[{"id":"goto1682956481968","function":"goto","view":"auth_destination"}],"oninvalid":[{"id":"set1682968214170","function":"set","what":"@property.error","value":"signup_error"},{"id":"popup1682968214170","function":"popup","dialog":"generic_error"}],"onexists":[{"id":"set1682968234440","function":"set","what":"@property.error","value":"signup_exists"},{"id":"popup1682968234440","function":"popup","dialog":"generic_error"}],"onweakpassword":[{"id":"set1682968249991","function":"set","what":"@property.error","value":"signup_weak"},{"id":"popup1682968249991","function":"popup","dialog":"generic_error"}]}],"if":{"what":"@property.valid","is":true},"comments":"IF VALID"}], options)
	}
	const headerLogo1682955275856OnClick = (options) => {
		run([{"id":"goto1682955275856","function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('email_verification.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="bodyBuildType1682955275857"
				direction="vertical"
				className="w-100 min-h-100 fg-100 position-relative" >
				<Group
					id="bodySpacerTop1682955275857"
					direction="vertical"
					className="w-100 h-fill position-relative" >
					<Group
						id="homeHeader1682955275857"
						direction="vertical"
						className="w-100 h-auto position-relative p-32px" >
						<Image
							id="headerLogo1682955275856"
							onClick={headerLogo1682955275856OnClick}
							src={logo}
							className="w-192px h-auto position-relative" />
					</Group>
				</Group>
				<Group
					id="formWrapperLang1682955275857"
					direction="vertical"
					className="max-w-1000px w-100 h-auto align-center position-relative" >
					<Text
						id="stepTitleLang1682955275857"
						tag="h1"
						className="w-100 h-auto align-center position-relative text-primary ff-titles text-center" >
						{t('email_verification.stepTitleLang1682955275857')}
					</Text>
					<Text
						id="stepInstructionsType1682955275857"
						className="w-80 h-auto align-center position-relative mt-6px text-primary-text ff-titles text-center" >
						{t('email_verification.stepInstructionsType1682955275857')}
					</Text>
					<Group
						id="verificationCodeWrapper"
						direction="vertical"
						className="w-80 h-auto align-center position-relative mt-24px" >
						<Text
							id="verificationCodeLabel"
							className="w-100 h-auto position-relative ff-regular" >
							{t('email_verification.verificationCodeLabel')}
						</Text>
						<Group
							id="verificationCodeInputWrapper"
							direction="vertical"
							className="w-100 h-auto border-top border-bottom border-start border-end align-center position-relative p-16px mt-8px" >
							<Input.Text
								id="verificationCodeInput"
								name="text"
								src="@property.verification"
							size="md"
								className="w-100 min-h-100 fg-100 border-white position-relative" />
						</Group>
					</Group>
					<Group
						id="passwordWrapper"
						direction="vertical"
						className="w-80 h-auto align-center position-relative mt-24px" >
						<Text
							id="passwordLabel"
							className="w-100 h-auto position-relative ff-regular" >
							{t('email_verification.passwordLabel')}
						</Text>
						<Group
							id="verificationCodeInputWrapper1682955632561"
							direction="vertical"
							className="w-100 h-auto border-top border-bottom border-start border-end align-center position-relative p-16px mt-8px" >
							<Input.Text
								id="verificationCodeInput1682955632561"
								name="text"
								src="@property.password"
							size="md"
								className="w-100 min-h-100 fg-100 border-white position-relative" />
						</Group>
					</Group>
					<Text
						id="continueButtonType1682955275857"
						onClick={continueButtonType1682955275857OnClick}
						className="w-auto h-auto border-top border-bottom border-start border-end align-center position-relative px-48px py-16px mt-48px" >
						{t('email_verification.continueButtonType1682955275857')}
					</Text>
				</Group>
				<Group
					id="bodySpacerBottom1682955275857"
					direction="vertical"
					className="w-100 h-fill position-relative" >
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
